import { envs } from "./envs";

export const EVENT_PAGE_URL = "https://lp.wanpass.me/event";

export const BUSINESS_REGISTER_FORM_DOM_ID = "register-form";

const STATIC_BASE = "https://wanpass.me"; // See `static` directory (../../static/). It will be deployed to host top level.
export const STATIC_OG_DEFAULT = STATIC_BASE + "/og/og-default.png";
export const STATIC_OG_BUSINESS_LP = STATIC_BASE + "/og/og-business-lp.png";
export const YOUTUBE = {
  BUSINESS_INTRODUCTION: "https://www.youtube.com/embed/UkuhwCLYIuA",
};

const DEFAULT_TRACKING_ID = "lp_button";

const ENTRY_BASE =
  envs.FLAVOR === "production"
    ? "https://entry.wanpass.me"
    : "https://dev.entry.wanpass.me";

export const getAppUrls = (trackingId_?: string) => {
  const trackingId = trackingId_ || DEFAULT_TRACKING_ID;
  return {
    ios: `${ENTRY_BASE}/install/ios/${trackingId}`,
    android: `${ENTRY_BASE}/install/android/${trackingId}`,
  };
};

export const getQrImageUrl = (urlOrigin: string, { size = 150 } = {}) =>
  `https://api.qrserver.com/v1/create-qr-code/?size=${size}x${size}&margin=10&data=${encodeURIComponent(
    urlOrigin
  )}`;
