import React from "react";
import { LOCAL_AREAS, LocalArea } from "../../config/LocalArea";
import styled from "styled-components";
import { useAppUrls } from "../../hooks/urlHooks";

export default function DebugAreas() {
  return (
    <table style={{ overflow: "scroll" }}>
      {LOCAL_AREAS.map((area) => (
        <Tr area={area} key={area.key} />
      ))}
    </table>
  );
}

function Tr({ area }: { area: LocalArea }) {
  const values = Object.values(area);
  const appUrls = useAppUrls({ area });
  return (
    <tr>
      <Td>
        <a href={`/${area.key}`}>/{area.key}</a>
      </Td>
      {values.map((value, i) => (
        <Td key={i}>{value}</Td>
      ))}
      <Td>
        <a href={appUrls.qr.ios}>
          <img src={appUrls.qr.ios} height="30" />
        </a>
      </Td>
    </tr>
  );
}

const Td = styled.td`
  white-space: nowrap;
  border: solid #000 1px;
  padding: 4px;
`;
